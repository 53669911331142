import React, { useRef } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useDetectOutsideClick } from "./useDetectOutsideClick"
import { Button } from "../../modules/structure/theme/button.component"
import breakpoint from "styled-components-breakpoint"
import { useTranslation } from "../../modules/translation/translate.component"
import { Arrow } from "../../modules/structure/theme/arrow.component"

const FilterSelect = styled.div`
  width: 78vw;
  margin: 8px 0px;
  padding: 8px 8px 8px 15px;
  border-radius: 3px;
  border: solid 1px ${props => props.theme.colors.grey.g600};
  background-color: white;
  position: relative;
  ${breakpoint(`medium`)`
       width: 260px;
  `}
`

const FilterTitle = styled.div`
  display: flex;
  background-color: white;
  justify-content: space-between;
`

const FilterModal = styled.div`
  position: absolute;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  transition: opacity 0.1s ease, transform 0.4s ease, visibility 0.4s;
  visibility: ${props => (props.isActive ? `visible` : `hidden`)};
  transform: translateY(${props => (props.isActive ? `0` : `-10px`)});
  opacity: ${props => (props.isActive ? `1` : `0`)};
  left: -1px;
  margin-left: auto;
  margin-right: auto;

  background: white;
  border: solid 1px ${props => props.theme.colors.grey.g600};
  border-top-style: none;
  z-index: 1;
  width: 78vw;
  ${breakpoint(`medium`)`
       width: 260px;
  `}
`

const FilterPlaceHolder = styled.div`
  background: white;
  z-index: 1;
  margin: 0px 12px;
`

const ApplyButton = styled(Button)`
  color: white;
  border-color: black;
  background-color: black;
  border-radius: 4px;
  margin: 8px auto;
`

export default function DropDownMenu ({ children, placeholder }) {
  const dropdownRef = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false)
  const { t } = useTranslation()

  return (
    <FilterSelect data-testid="dropdown_menu" ref={dropdownRef}>
      <FilterTitle onClick={() => setIsActive(!isActive)}>
        <FilterPlaceHolder>{placeholder}</FilterPlaceHolder>
        <Arrow small down isOpened={isActive} />
      </FilterTitle>
      <FilterModal isActive={isActive} data-testid="menu_open">
        {children}
        <ApplyButton data-testid="apply_button" onClick={() => setIsActive(false)}>
          {t(`common:generic.button_apply`)}
        </ApplyButton>
      </FilterModal>
    </FilterSelect>
  )
}

DropDownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.string.isRequired,
}
