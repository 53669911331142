import React, { memo, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { colors, condition, fontSizes, modules, useThemeContext } from "../../structure/theme"
import { flatMap, sortStringBy, uniqBy } from "../../../utils/collection"
import {
  getPrice,
  HOUSINGTYPE_APPARTEMENT,
  HOUSINGTYPE_MAISON,
  HOUSINGTYPE_MIXTE,
  useHousingTypeGroups,
  useUniverseSlugGroups,
} from "../product.utils"
import House from "../../../components/pictos/house.component"
import Flat from "../../../components/pictos/flat.component"
import UniversePicto from "../../universe/universe.picto"
import FilterCheckbox from "../../../components/filterCheckbox.component"
import breakpoint from "styled-components-breakpoint"
import { useCampaignContext } from "../../campaign/campaign.provider"
import _noop from "lodash/noop"
import { UNIVERSE_PLOMBERIE_SLUG } from "../../universe/universe.utils"
import { Bold, flexCenter } from "../../structure/theme/styles.component"
import ProductCard from "./productCard.component"
import { usePageContext } from "../../navigation/pageContext.provider"
import { addEventTracking, joinCodesTracking, useEventProductFinderTracking } from "../../thirdParties/gtm/tagManager"
import { useCoBrand } from "../../coBrand/coBrand.provider"
import { Trans, useTranslation } from "../../translation/translate.component"
import DropDownMenu from "../../../components/dropdownMenu/dropdowMenu.component"
import Close from "../../../components/pictos/close.component"

const checkedPrimaryColor = colors(`checked`, `primary`, `grey.g600`)
const checkedColor = props => props.$color

const Filters = styled.div`
  display: block;
  background: ${colors(`grey.g300`)};
  padding: 16px 0;
  width: 100%;
  margin: 15px 0;
  ${breakpoint(`medium`)`
     display: flex;
  `}
`

const SelectedOffer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  flex-wrap: wrap;
`

const SelectedOfferText = styled.div`
  display: block;
`

const SelectedOfferList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${breakpoint(`medium`)`
      display: flex;
  `}
`

const Filter = styled.div`
  ${flexCenter};
  width: 100%;
  padding: 0px 20px;
  flex-wrap: wrap;
  ${breakpoint(`medium`)`
       width: auto;
       margin-left: 16px;
  `}
`

const HousingTypeFilter = styled(Filter)`
  color: ${modules(`product.finder.selectionColor`)};
  font-weight: 900;
`

const HousingCheckboxWrapper = styled.div`
  display: flex;
`

const HousingTypeWrapper = styled.div`
  ${flexCenter};
  border: 2px solid ${checkedPrimaryColor};
  border-radius: 50%;
  background-color: white;
  width: 40px;
  height: 40px;
`

const Choice = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
`

const HousingTypeLabel = styled.div`
  display: ${condition(`desktopOnly`, `none`, `block`)};
  color: ${checkedPrimaryColor};
  margin-left: 8px;

  ${breakpoint(`medium`)`
     display: ${condition(`mobileOnly`, `none`, `block`)};
  `}
`

const UniverseChoice = styled(Choice)`
  display: flex;
  color: ${checkedColor};
  background-color: white;
  padding: 7px 16px;
  margin: 3px 14px;
  ${breakpoint(`small`)`
     margin: 0 14px;
  `}
`

const UniverseSelected = styled(Choice)`
  display: flex;
  border: 1px solid ${checkedColor};
  background-color: white;
  padding: 11px 11.5px 9px 11px;
  border-radius: 5px;
  margin-left: 8px;
  margin-top: 2px;
`

const UniverseName = styled.span`
  ${breakpoint(`medium`)`
    display: block;
  `}
`

const ProductCardList = styled.div`
  padding: 0 15px;

  ${breakpoint(`medium`)`
     padding: 0 0;
  `}
`

const ProductNoResult = styled.div`
  padding: 8px 15px;
  width: auto;
  background-color: rgb(238, 230, 192);
  border-radius: 8px;
  border: solid 1px rgb(210, 210, 210);
  margin: 0px 15px;
  text-align: center;
  ${breakpoint(`medium`)`
    margin: 0px
  `}
`

const HousingTypeCheckbox = styled(FilterCheckbox)`
  background-color: white;
  border-color: ${checkedPrimaryColor};
`

const HousingTypeSelection = styled.div`
  font-weight: 900;
  margin-right: 16px;
`

const FilterTypeSelection = styled.div`
  display: none;
  font-weight: 900;
  margin-right: 25px;

  ${breakpoint(`medium`)`
    display: block;
  `}
`

const HousingTypeSeparator = styled.div`
  visibility: hidden;
  width: 28px;
`

const SeparatorLine = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;

  :before,
  :after {
    content: "";
    border-bottom: 1px solid ${colors(`grey.g600`)};
    width: 100%;
  }
`

// Svg triangle based on https://weareoutman.github.io/rounded-polygon/
const SeparatorTriangle = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60'%3E%3Cpath transform='scale(1,-1) translate(0,-60)' stroke='none' fill='%23ababab' d='M25.669872981078 7.287187078898a5 5 0 0 1 8.6602540378444 0l23.339745962156 40.425625842204a5 5 0 0 1 -4.3301270189222 7.5l-46.679491924311 0a5 5 0 0 1 -4.3301270189222 -7.5'%3E%3C/path%3E%3C/svg%3E");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  width: 40px;
  height: 20px;
  border: none;
  margin: 0 5px;
`

const SeparatorTitle = styled.div`
  text-align: center;
  color: ${colors(`primary`)};
  font-size: ${fontSizes(`x_large`)};
  font-weight: 900;
  margin-top: 25px;
`

const SeparatorBody = styled.div`
  margin: 0 auto 20px;
  text-align: center;
  max-width: 700px;
  font-weight: 300;
  font-size: ${fontSizes(`x_large`)};
`

const UNIVERSE_SELECT_MODE = {
  CHECKBOX: `checkbox`,
  RADIO: `radio`,
}

const UNIVERSE_FILTER_MODE = {
  STATIC: `static`,
  DYNAMIC: `dynamic`,
}

function ProductFinder ({
  products,
  selectMode = UNIVERSE_SELECT_MODE.CHECKBOX,
  filterMode = UNIVERSE_FILTER_MODE.DYNAMIC,
  onChange = _noop,
  initialHousingType = HOUSINGTYPE_MAISON,
  initialUniverses = [UNIVERSE_PLOMBERIE_SLUG],
  isSearchPage = false,
}) {
  const { t } = useTranslation()
  const {
    colors: { primary, grey },
    modules: {
      product: {
        picto: { universeColor: themeUniversePictoColor },
      },
    },
  } = useThemeContext()
  const { coBrand } = useCoBrand()

  const isCobranded = coBrand !== null
  const pictoColor = isCobranded ? themeUniversePictoColor : undefined

  const { route } = usePageContext()

  const {
    [HOUSINGTYPE_APPARTEMENT]: flatProducts,
    [HOUSINGTYPE_MAISON]: houseProducts,
    [HOUSINGTYPE_MIXTE]: mixteProducts,
  } = useHousingTypeGroups(products)

  const [checkedHousingType, checkHousingType] = useState(initialHousingType)
  const [filterReady, setFilterReady] = useState(false)
  const [selectedUniverses, selectUniverses] = useState(() =>
    initialUniverses.reduce((acc, universeSlug) => {
      return { ...acc, [universeSlug]: true }
    }, []),
  )
  const firstSelectedUniverses = useRef(selectedUniverses)
  const { voucher } = useCampaignContext()

  const checkedHousingTypeHouse = checkedHousingType === HOUSINGTYPE_MAISON
  const withHousingTypeFilter = flatProducts.length > 0 && houseProducts.length > 0

  const currentSelectedUniverses = firstSelectedUniverses.current || selectedUniverses

  const filteredProductsByHousingType = useMemo(
    function findProductsByHousingType () {
      if (withHousingTypeFilter) {
        return checkedHousingTypeHouse ? houseProducts.concat(mixteProducts) : flatProducts.concat(mixteProducts)
      }

      return products
    },
    [products, mixteProducts, flatProducts, houseProducts, checkedHousingTypeHouse],
  )

  const groupedProductsByUniverse = useUniverseSlugGroups(filteredProductsByHousingType)

  const [universes, nbTotalUniverses] = useMemo(
    function findDistinctUniverses () {
      const allUniverses = products.reduce(flatMap(`universes`), []).reduce(uniqBy(`slug`), [])

      // If dynamic mode, we only show universe filters containing products
      const innerUniverses = UNIVERSE_FILTER_MODE.DYNAMIC
        ? Object.keys(groupedProductsByUniverse).map(universeSlug =>
            allUniverses.find(universe => universe.slug === universeSlug),
          )
        : allUniverses

      // Sort universes by name to avoid moving filters when housing type change
      innerUniverses.sort(sortStringBy(`name`))

      // Business rule: move plomberie universe at position 0 if exists (and not already at 0 :)
      const plomberieIndex = innerUniverses.findIndex(universe => universe.slug === UNIVERSE_PLOMBERIE_SLUG)

      if (plomberieIndex > 0) {
        const plomberieUniverse = innerUniverses[plomberieIndex]
        innerUniverses.splice(plomberieIndex, 1)
        innerUniverses.unshift(plomberieUniverse)
      }

      return [innerUniverses, allUniverses.length]
    },
    [products, filteredProductsByHousingType, filterMode],
  )

  const [topProducts, bottomProducts] = useMemo(
    function findProductBySelectedUniverses () {
      if (firstSelectedUniverses.current) {
        const hasProducts = Object.keys(firstSelectedUniverses.current).some(
          universeSlug => groupedProductsByUniverse[universeSlug],
        )

        if (hasProducts) {
          // Here we removed some initialized selected universe (initialUniverses)
          // which are not in the dynamic list of universes
          for (const universeSlug in firstSelectedUniverses.current) {
            if (!universes.find(universe => universe.slug === universeSlug)) {
              delete firstSelectedUniverses.current[universeSlug]
            }
          }
        } else {
          // We may have no product based on initial selected universes and we want to avoid an empty product list
          // If so, we fallback to the first universe of the list
          for (const universe of universes) {
            if (groupedProductsByUniverse[universe.slug]) {
              firstSelectedUniverses.current = { [universe.slug]: true }
              break
            }
          }
        }
      }

      const isUniverseSelected = Object.keys(firstSelectedUniverses.current || selectedUniverses).length > 0

      // When no selected universe, we show all products sorted by nb universes and price
      if (!isUniverseSelected) {
        const innerProducts = filteredProductsByHousingType
          .map(product => ({
            product,
            price: getPrice(product, voucher).price || 0,
            nbUniverses: product.universes.length,
          }))
          .sort((a, b) => {
            if (a.nbUniverses > b.nbUniverses) return 1
            if (a.nbUniverses < b.nbUniverses) return -1
            if (a.price > b.price) return 1
            if (a.price < b.price) return -1

            return 0
          })
          .map(({ product }) => product)

        return [innerProducts, []]
      }

      const _selectedUniverses = Object.keys(firstSelectedUniverses.current || selectedUniverses)
      const nbSelectedUniverses = _selectedUniverses.length

      const productsInfo = filteredProductsByHousingType
        .map(product => ({
          product,
          price: getPrice(product, voucher).price || 0,
          nbUniverses: product.universes.length,
          nbMatchedUniverses: _selectedUniverses.reduce(
            (counter, slug) => (product.universes.some(universe => universe.slug === slug) ? counter + 1 : counter),
            0,
          ),
        }))
        .filter(({ nbMatchedUniverses }) => nbMatchedUniverses > 0)
        .sort((a, b) => {
          // When -1, `a` gets higher rank

          // Products with the exact number of matched universes
          // prettier-ignore
          if (a.nbMatchedUniverses === nbSelectedUniverses && b.nbMatchedUniverses !== nbSelectedUniverses) return -1
          // prettier-ignore
          if (b.nbMatchedUniverses === nbSelectedUniverses && a.nbMatchedUniverses !== nbSelectedUniverses) return 1

          // Products with the exact number of universes selected
          if (a.nbUniverses === nbSelectedUniverses && b.nbUniverses !== nbSelectedUniverses) {
            return -1
          }
          if (b.nbUniverses === nbSelectedUniverses && a.nbUniverses !== nbSelectedUniverses) {
            return 1
          }

          // Products with the maximum matched universes
          if (a.nbMatchedUniverses > b.nbMatchedUniverses) return -1
          if (b.nbMatchedUniverses > a.nbMatchedUniverses) return 1

          // Products with the lower price
          if (a.price > b.price) return 1
          if (a.price < b.price) return -1

          return 0
        })

      if (!isSearchPage) {
        return [productsInfo.map(({ product }) => product), []]
      }

      const bothSideProducts = productsInfo.reduce(
        (acc, productInfo) => {
          const index =
            productInfo.nbMatchedUniverses === nbSelectedUniverses && productInfo.nbUniverses === nbSelectedUniverses
              ? 0
              : 1
          acc[index].push(productInfo.product)

          return acc
        },
        [[], []],
      )

      return bothSideProducts[0].length > 0 ? bothSideProducts : [bothSideProducts[1], []]
    },
    [
      universes,
      filteredProductsByHousingType,
      selectedUniverses,
      filterMode,
      isSearchPage,
      firstSelectedUniverses.current,
    ],
  )

  function addEventTrackingPerRoute () {
    switch (route) {
      case `results`:
        addEventTracking(`eventGA`, {
          eventCategory: `Filtre_Résultat`,
          eventAction: `Filtre_Résultat`,
          eventLabel: `${checkedHousingType} | ${joinCodesTracking(selectedUniverses)}`,
        })
    }
  }

  function toggleUniverse (universeSlug) {
    if (UNIVERSE_SELECT_MODE.CHECKBOX && selectedUniverses[universeSlug]) {
      const newSelectedUniverses = { ...selectedUniverses }
      delete newSelectedUniverses[universeSlug]
      selectUniverses(() => newSelectedUniverses)
    } else {
      selectUniverses({
        ...selectedUniverses,
        [universeSlug]: true,
      })
    }

    setFilterReady(true)
  }

  function changeHousingType (housingType) {
    checkHousingType(housingType)
  }

  const withUniverseFilter = nbTotalUniverses > 1
  const nbResultProducts = topProducts.length

  useEventProductFinderTracking(
    [...topProducts, ...bottomProducts],
    `Page Résultat - ${checkedHousingType} | ${joinCodesTracking(currentSelectedUniverses)}`,
    currentSelectedUniverses,
  )

  // On mount, flush ssr selected universe
  useEffect(() => {
    if (firstSelectedUniverses.current) {
      selectUniverses(firstSelectedUniverses.current)
      firstSelectedUniverses.current = null
    } else if (filterMode === UNIVERSE_FILTER_MODE.DYNAMIC) {
      selectUniverses(() => {
        for (const universe of universes) {
          if (groupedProductsByUniverse[universe.slug]) {
            return { [universe.slug]: true }
          }
        }

        return {}
      })

      setFilterReady(true)
    }
  }, [universes, groupedProductsByUniverse])

  // Trigger onChange when selected universes or housing type changed
  useEffect(() => {
    onChange(checkedHousingType, Object.keys(currentSelectedUniverses))

    if (!filterReady) return

    addEventTrackingPerRoute()

    setFilterReady(false)
  }, [onChange, currentSelectedUniverses, checkedHousingType])

  return (
    <>
      <Filters data-testid="product_finder">
        {withUniverseFilter && (
          <Filter>
            <FilterTypeSelection>
              {`${t(`common:product.product_finder_filter_selection_text_2`)} :`}
            </FilterTypeSelection>
            <DropDownMenu placeholder={t(`common:product.product_finder_placeholder`)}>
              {universes.map(universe => {
                const { name, slug, primaryColor } = universe

                return (
                  <UniverseChoice
                    key={slug}
                    $color={isCobranded ? pictoColor : primaryColor}
                    $checked={currentSelectedUniverses[slug]}
                    onClick={() => toggleUniverse(slug)}
                    data-testid={`filter.${slug}.${Number(!!currentSelectedUniverses[slug])}`}
                  >
                    <HousingTypeCheckbox checked={currentSelectedUniverses[slug]} />
                    <UniversePicto universe={universe} size={30} color={pictoColor} />
                    <UniverseName>&nbsp;{name}</UniverseName>
                  </UniverseChoice>
                )
              })}
            </DropDownMenu>
          </Filter>
        )}

        {withHousingTypeFilter && (
          <HousingTypeFilter withUniverseFilter={withUniverseFilter}>
            <HousingTypeSelection>
              {`${t(`common:product.product_finder_filter_selection_text`)} :`}
            </HousingTypeSelection>
            <HousingCheckboxWrapper>
              <Choice
                onClick={() => changeHousingType(HOUSINGTYPE_MAISON)}
                data-testid={`filter.maison.${Number(checkedHousingTypeHouse)}`}
              >
                <HousingTypeCheckbox checked={checkedHousingTypeHouse} />
                <HousingTypeWrapper checked={checkedHousingTypeHouse}>
                  <House color={checkedHousingTypeHouse ? primary : grey.g600} size="22" />
                </HousingTypeWrapper>
                <HousingTypeLabel checked={checkedHousingTypeHouse}>
                  {t(`common:generic.housing_type_house`)}
                </HousingTypeLabel>
              </Choice>
              <HousingTypeSeparator />
              <Choice
                onClick={() => changeHousingType(HOUSINGTYPE_APPARTEMENT)}
                data-testid={`filter.appartement.${Number(!checkedHousingTypeHouse)}`}
              >
                <HousingTypeCheckbox checked={!checkedHousingTypeHouse} />
                <HousingTypeWrapper checked={!checkedHousingTypeHouse}>
                  <Flat color={!checkedHousingTypeHouse ? primary : grey.g600} size="22" />
                </HousingTypeWrapper>
                <HousingTypeLabel checked={!checkedHousingTypeHouse} desktopOnly>
                  {t(`common:generic.housing_type_flat`)}
                </HousingTypeLabel>
                <HousingTypeLabel checked={!checkedHousingTypeHouse} mobileOnly>
                  <Trans
                    t={t}
                    i18nKey="common:generic.housing_type_flat_short"
                    components={{
                      sup: <sup />,
                    }}
                  />
                </HousingTypeLabel>
              </Choice>
            </HousingCheckboxWrapper>
          </HousingTypeFilter>
        )}
      </Filters>
      <SelectedOffer>
        <SelectedOfferText data-testid="product_finder_search_result">
          <Trans
            t={t}
            i18nKey="common:product.product_finder_search"
            values={{
              count: nbResultProducts,
            }}
            components={{
              bold: <Bold />,
            }}
          />
        </SelectedOfferText>
        <SelectedOfferList>
          {universes
            .filter(universe => currentSelectedUniverses[universe.slug])
            .map(universe => {
              const { name, slug, primaryColor } = universe

              return (
                <UniverseSelected
                  key={slug}
                  $color={isCobranded ? pictoColor : primaryColor}
                  $checked={currentSelectedUniverses[slug]}
                  data-testid={`menu.${slug}.${Number(!!currentSelectedUniverses[slug])}`}
                >
                  <UniverseName>&nbsp;{name}</UniverseName>
                  <Close
                    data-testid={`selected.${slug}.filter.close`}
                    thin
                    color="black"
                    small
                    onClick={() => toggleUniverse(slug)}
                  />
                </UniverseSelected>
              )
            })}
        </SelectedOfferList>
      </SelectedOffer>
      {nbResultProducts === 0 ? (
        <ProductNoResult>{t(`common:product.product_finder_no_result`)}</ProductNoResult>
      ) : null}

      <ProductCardList data-testid="product_finder_top_products">
        {topProducts.map((product, index) => {
          return (
            <ProductCard
              data-testid={`${product.slug
                .split(`/`)
                .slice(-2, -1)[0]
                .replace(/-/g, `_`)}_product_card`}
              key={product.id}
              position={index}
              product={product}
            />
          )
        })}
      </ProductCardList>

      {bottomProducts.length > 0 && (
        <>
          <SeparatorLine data-testid="product_finder_separator">
            <SeparatorTriangle />
          </SeparatorLine>
          <SeparatorTitle>{t(`common:product.product_finder_separator_title`)}</SeparatorTitle>
          <SeparatorBody>
            <Trans
              t={t}
              i18nKey="common:product.product_finder_separator_body"
              components={{
                bold: <Bold />,
              }}
            />
          </SeparatorBody>

          <ProductCardList data-testid="product_finder_bottom_products">
            {bottomProducts.map((product, index) => {
              return (
                <ProductCard
                  data-testid={`${product.slug
                    .split(`/`)
                    .slice(-2, -1)[0]
                    .replace(/-/g, `_`)}_product_card`}
                  key={product.id}
                  position={index}
                  product={product}
                />
              )
            })}
          </ProductCardList>
        </>
      )}
    </>
  )
}

ProductFinder.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      housingType: PropTypes.string.isRequired,
      universes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
  selectMode: PropTypes.oneOf(Object.values(UNIVERSE_SELECT_MODE)),
  filterMode: PropTypes.oneOf(Object.values(UNIVERSE_FILTER_MODE)),
  onChange: PropTypes.func,
  initialHousingType: PropTypes.oneOf([HOUSINGTYPE_APPARTEMENT, HOUSINGTYPE_MAISON]),
  initialUniverses: PropTypes.arrayOf(PropTypes.string.isRequired),
  isSearchPage: PropTypes.bool,
}

export default memo(ProductFinder)
